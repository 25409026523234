/*
 * @Author: your name
 * @Date: 2021-07-23 13:33:18
 * @LastEditTime: 2021-09-16 11:38:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Page from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Page',
    component: Page,
    children: [
      //首页
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: { Tab: 1 }
      },
      //关于我们
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: { Tab: 2 }
      },
      //新闻资讯
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news/index.vue'),
        meta: { Tab: 3 }
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/news/detail.vue'),
        meta: { Tab: 3 }
      },
      //志愿项目
      {
        path: '/project',
        name: 'project',
        component: () => import('@/views/project/index.vue'),
        meta: { Tab: 4 }
      },
      {
        path: '/projectDetail',
        name: 'projectDetail',
        component: () => import('@/views/project/detail.vue'),
        meta: { Tab: 4 }
      },
      //志愿活动
      {
        path: '/activity',
        name: 'activity',
        component: () => import('@/views/activity/index.vue'),
        meta: { Tab: 5 }
      },
      {
        path: '/activityDetail',
        name: 'activityDetail',
        component: () => import('@/views/activity/detail.vue'),
        meta: { Tab: 5 }
      },
      //志愿服务队
      {
        path: '/serve',
        name: 'serve',
        component: () => import('@/views/serve/index.vue'),
        meta: { Tab: 6 }
      },
      {
        path: '/serveDetail',
        name: 'serveDetail',
        component: () => import('@/views/serve/detail.vue'),
        meta: { Tab: 6 }
      },
      //管理中心
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index.vue'),
        children: [
          //个人中心
          {
            path: '/',
            name: 'userCenter',
            component: () => import('@/views/user/userCenter/index.vue'),
            meta: { Index: 1 }
          },
          //证书申请
          {
            path: 'apply',
            name: 'apply',
            component: () => import('@/views/user/apply/index.vue'),
            meta: { Index: 2 }
          },
          //我的组织
          {
            path: 'organization',
            name: 'organization',
            component: () => import('@/views/user/organization/index.vue'),
            meta: { Index: 3 }
          },
          //管理中心
          {
            path: 'admin',
            name: 'admin',
            component: () => import('@/views/user/admin/index.vue'),
            meta: { Index: 3 }
          },
          //报名活动
          {
            path: 'sign',
            name: 'sign',
            component: () => import('@/views/user/sign/index.vue'),
            meta: { Index: 4 }
          },
          //活动记录
          {
            path: 'record',
            name: 'record',
            component: () => import('@/views/user/record/index.vue'),
            meta: { Index: 5 }
          },
          //服务证明
          {
            path: 'prove',
            name: 'prove',
            component: () => import('@/views/user/prove/index.vue'),
            meta: { Index: 6 }
          },
          //证明材料
          {
            path: 'proveMaterials',
            name: 'proveMaterials',
            component: () => import('@/views/user/prove/materials.vue'),
            meta: { Index: 6 }
          },

          //我的关注
          {
            path: 'focus',
            name: 'focus',
            component: () => import('@/views/user/focus/index.vue'),
            meta: { Index: 7 }
          },
          //账户安全
          {
            path: 'account',
            name: 'account',
            component: () => import('@/views/user/account/index.vue'),
            meta: { Index: 8 }
          },

        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/404/privacy.vue')
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/404/protocol.vue')
  },
  //身份认证
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/auth/index.vue')
  },
  //实名认证
  {
    path: '/cert',
    name: 'cert',
    component: () => import('@/views/cert/index.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes
})

export default router
