<!--
 * @Author: your name
 * @Date: 2021-07-24 13:19:00
 * @LastEditTime: 2021-09-16 15:51:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\publicHeader.vue
-->
<template>
  <header>
    <div class="top container flex-h flex-vc">
      <img class="topLogo" src="../assets/img/logo.png" />
      <div class="location">
        <span>[ {{ location.city }} ]</span>
        <a @click="showDialog">切换</a>
      </div>
    </div>
    <div class="nav container flex-h flex-vc">
      <ul class="flex-h">
        <li
          :class="{ active: Tab == 1 }"
          @click="$router.push({ name: 'home' })"
        >
          <span>{{ column[0] }}</span>
        </li>
        <li
          :class="{ active: Tab == 2 }"
          @click="$router.push({ name: 'about' })"
        >
          <span>{{ column[1] }}</span>
        </li>
        <li
          :class="{ active: Tab == 3 }"
          @click="$router.push({ name: 'news' })"
        >
          <span>{{ column[2] }}</span>
        </li>
        <li
          :class="{ active: Tab == 4 }"
          @click="$router.push({ name: 'project' })"
        >
          <span>{{ column[3] }}</span>
        </li>
        <li
          :class="{ active: Tab == 5 }"
          @click="$router.push({ name: 'activity' })"
        >
          <span>{{ column[4] }}</span>
        </li>
        <li
          :class="{ active: Tab == 6 }"
          @click="$router.push({ name: 'serve' })"
        >
          <span>{{ column[5] }}</span>
        </li>
      </ul>
      <div v-if="token && Tab"  @click="$router.push({ path: '/user' })" class="flex-h flex-hc flex-vc">
        <img class="avatar" :src="avatar" />
        <div class="flex-v flex-vc">
          <p class="a_status" v-if="affirm == 1">注册志愿者</p>
          <p class="a_status" v-if="affirm == 2">学生志愿者</p>
          <p class="a_status" v-if="affirm == 3">会员志愿者</p>
          <p class="adminBtn">
            管理中心
          </p>
        </div>
      </div>
      <div class="flex-h flex-hc flex-vc" v-if="token && !Tab">
        <img class="avatar" :src="avatar" />
        <div class="flex-v flex-vc">
          <p class="a_status" v-if="affirm == 1">注册志愿者</p>
          <p class="a_status" v-if="affirm == 2">学生志愿者</p>
          <p class="a_status" v-if="affirm == 3">会员志愿者</p>
          <p class="logout" @click="logout">退出</p>
        </div>
      </div>
      <div v-if="!token" class="h-btn" @click="$router.push({ name: 'login' })">
        登录 / 注册
      </div>
    </div>
    <transition name="fade">
      <div class="poput" v-if="show">
        <div class="box">
          <div class="boxTitle">请选择地区</div>
          <ul class="flex-h">
            <li
              @click="check(item, index)"
              :class="{ pick: currentIndex == index }"
              v-for="(item, index) in locationList"
              :key="index"
            >
              {{ item.city }}
            </li>
          </ul>
          <div class="buttons flex-h flex-vc flex-hc">
            <div class="button1" @click="show = false">关 闭</div>
            <div class="button2" @click="confirm">确 定</div>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>
<script>
import { getLocation, setLocation } from "@/utils/auth";
import { addressList } from "@/api/index";
export default {
  data() {
    return {
      show: false,
      currentIndex: null,
      locationList: [],
    };
  },
  methods: {
    check(item, index) {
      this.currentIndex = index;
    },
    confirm() {
      this.show = false;
      let item = this.locationList[this.currentIndex];
      this.$store.commit("SET_LOCATION", item);
      setLocation(item);
    },
    showDialog() {
      this.show = true;
      let locationId = this.location.addrId;
      this.locationList.forEach((item, index) => {
        if (item.addrId == locationId) {
          this.currentIndex = index;
        }
      });
    },
    logout() {
      this.$message({
        type: "success",
        message: "退出成功！",
      });
      this.$store.commit("logout");
      this.$router.push({ name: "home" });
    },
  },
  created() {
    let location = getLocation();
    this.$store.commit("SET_LOCATION", location);
    addressList().then((res) => {
      if (res.data == null) this.locationList = [{ addrId: "", city: "全国" }];
      else this.locationList = [{ addrId: "", city: "全国" }, ...res.data];
    });
  },
  computed: {
    Tab: function () {
      // `this` 指向 vm 实例
      return this.$route.meta.Tab;
    },
    avatar() {
      return this.$store.state.avatar;
    },
    location() {
      return this.$store.state.location;
    },
    column() {
      return this.$store.state.column;
    },
    token() {
      return this.$store.state.token;
    },
    affirm() {
      return this.$store.state.affirm;
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  li {
    list-style-type: none;
  }
  min-width: 1200px;
  .top {
    height: 118px;
    justify-content: space-between;
    .topLogo {
      width: 248px;
    }
    .location {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #635252;
      a {
        cursor: pointer;
        margin-left: 10px;
        color: #e5413e;
      }
    }
  }
  .nav {
    justify-content: space-between;
    ul {
      height: 62px;
      li {
        width: 110px;
        height: 100%;
        text-align: center;
        line-height: 62px;
        margin: 0 10px;
        color: #696969;
        span {
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
        &.active {
          background: #ff6e6e;

          color: #ffffff;
        }
      }
    }
    .h-btn {
      width: 154px;
      height: 40px;
      background: linear-gradient(180deg, #ff9102 0%, #ff1d01 100%);
      border-radius: 24px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 11px;
    }
    .a_status {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 900;
      color: #696969;
    }
    .adminBtn {
      cursor: pointer;
      margin-top: 5px;
      width: 62px;
      height: 18px;
      background: #ff6e6e;
      border-radius: 9px;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
    .logout {
      cursor: pointer;
      width: 32px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff6e6e;
      margin-top: 6px;
    }
  }
  .poput {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    .box {
      width: 645px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 9px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 36px 70px 23px 70px;
      .boxTitle {
        font-size: 20px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #555555;
        text-align: center;
        margin-bottom: 50px;
      }
      ul {
        flex-wrap: wrap;
        overflow-y: auto;
        li {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #919191;
          cursor: pointer;
          padding: 0 10px;
          margin-bottom: 10px;
          border-radius: 10px;
          height: 24px;
          line-height: 24px;
          &.pick {
            background: #ff6e6e;
            color: #ffffff;
          }
        }
      }
    }
    .buttons {
      margin-top: 60px;
      .button1 {
        width: 140px;
        height: 40px;
        border-radius: 24px;
        border: 1px solid #ff6e6e;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff6e6e;
        line-height: 40px;
        margin-right: 20px;
      }
      .button2 {
        width: 140px;
        height: 40px;
        background: #ff6e6e;
        border-radius: 24px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        margin-left: 20px;
      }
    }
  }
}
</style>