<!--
 * @Author: your name
 * @Date: 2021-07-24 11:43:05
 * @LastEditTime: 2021-08-17 10:02:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\index.vue
-->
<template>
  <div id="index">
    <publicHeader/>
    <section>
      <router-view/>
    </section>
    <publicFooter/>
  </div>
</template>
<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
export default {
  components: { publicHeader, publicFooter },
};

</script>
<style lang="scss" scoped>

</style>