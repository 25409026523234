/*
 * @Author: your name 
 * @Date: 2020-08-21 11:37:05
 * @LastEditTime: 2021-09-25 15:30:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lingganonline-web\src\admin\permission.js
 */
import router from './router'
import store from './store'
import { setToken, getToken, getUserInfo, setPrivilege, getPrivilege } from './utils/auth' // get token from cookie

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken()
  const hasUserInfo = getUserInfo()
  if (!store.state.token) {
    if (hasToken) {
      setToken(hasToken)
      if (hasUserInfo) {
        store.commit("login", hasUserInfo);
      }
      let Privilege = getPrivilege()
      store.commit("SET_PRIVILEGE", Privilege);
    }
  }
  if (store.state.affirm === 0 && to.name != 'cert' && to.name != 'login') {
    if(to.name == 'login'){
      next({ name: 'login' })
    }else{
      next({ name: 'cert' })
    }
  } else {
    next()
  }
})


