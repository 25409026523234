/*
 * @Author: your name
 * @Date: 2021-07-23 13:33:18
 * @LastEditTime: 2021-09-08 14:18:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {removeToken} from '@/utils/auth'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: "",
    userId: '',
    avatar: '',
    name: '',
    clientHeight: document.documentElement.clientHeight,//屏幕高度
    location: '',
    affirm: '',//是否认证 0未认证 1注册会员 2学生 3会员
    column:[],
    privilege:[],
  },
  mutations: {
    SET_COLUMN: (state, column) => {
      state.column = column
    },
    SET_AFFIRM(state, data) {
      state.affirm = data;
    },
    SET_PRIVILEGE: (state, arr) => {
      state.privilege = arr
    },
    SET_ID: (state, id) => {
      state.userId = id
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_HEIGHT(state, data) {
      state.clientHeight = data;
    },
    SET_LOCATION(state, data) {
      state.location = data;
    },
    login(state, userInfo){
      state.token = userInfo.token;
      state.userId = userInfo.encryptionId;
      state.name = userInfo.userName;
      state.avatar = userInfo.imageUrl;
      state.affirm = userInfo.userType;
    },
    logout(state){
      state.userId = '';
      state.name = '';
      state.avatar = '';
      state.affirm = '';
      state.token = '';
      removeToken()
    },
  },
  actions: {
  },
  modules: {
  }
})
