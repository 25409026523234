/*
 * @Author: your name
 * @Date: 2020-08-28 10:56:26
 * @LastEditTime: 2021-10-13 15:29:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lingganonline-admin\src\utils\request.js
 */
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'

// axios.defaults.headers.post['Content-Type'] = 'application/json'; 
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000,// request timeout
  
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['token'] = getToken() || ''
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if(response.status==204){
      var res ={code:200}
    }else{
      var res = response.data
    }
    
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
     
      if (res.code === 2002) {
        Message({
          message: '请登录',
          type: 'warning',
          duration: 5 * 1000
        })
        // to re-login
        store.commit("logout");
        router.push({name:'login'});
      }else{
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      // // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
     
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.response.data.msg ||error,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service