/*
 * @Author: your name
 * @Date: 2021-07-23 13:33:18
 * @LastEditTime: 2021-08-10 15:13:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/index.scss'
import './assets/css/element-variables.scss'
import '@/permission' // permission control
import { parseTime} from '@/utils/index'
//监听屏幕大小变化
store.commit('SET_HEIGHT', document.documentElement.clientHeight)
window.onresize = () => {
  setTimeout(() => {
    store.commit('SET_HEIGHT', document.documentElement.clientHeight)
  }, 300);
};
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.$formatDate = parseTime;
Vue.prototype.$baseURL = process.env.VUE_APP_BASE_API;
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
router.afterEach(() => {
    document.getElementsByClassName("el-scrollbar__wrap")[0].scroll(0, 0);
})
