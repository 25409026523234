/*
 * @Author: your name
 * @Date: 2020-08-28 10:56:26
 * @LastEditTime: 2021-08-24 15:46:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lingganonline-admin\src\utils\auth.js
 */
import Cookies from 'js-cookie'

const TokenKey = 'xh_token'
const UserInfo ='xh_UserInfo'
const Location ='xh_location'
const Privilege ='xh_privilege'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getUserInfo() {
  try {
    let userInfo=JSON.parse(Cookies.get(UserInfo))
    return userInfo
  } catch (error) {
    return{}
  }
}

export function setUserInfo(info) {
  return Cookies.set(UserInfo, info)
}

export function getPrivilege() {
  try {
    
    let list=JSON.parse(Cookies.get(Privilege))
    return list || []
  } catch (error) {
    return[]
  }
}

export function setPrivilege(info) {
  return Cookies.set(Privilege, info)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}

export function getLocation() {
  try {
    let location=JSON.parse(Cookies.get(Location))
    return location
  } catch (error) {
    return{ addrId: '', city: "全国" }
  }
}

export function setLocation(info) {
  return Cookies.set(Location, info)
}