/*
 * @Author: your name
 * @Date: 2020-08-28 10:56:26
 * @LastEditTime: 2021-10-13 17:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lingganonline-admin\src\api\index.js
 */
import request from '@/utils/request'
//登录
export function login(data) {
  return request({
    url: '/account/userLogin',
    method: 'post',
    data
  })
}
//注册
export function register(data) {
  return request({
    url: '/account/register',
    method: 'post',
    data
  })
}
//发送验证码
export function sendCode(data) {
  return request({
    url: '/account/sendCode',
    method: 'post',
    data
  })
}
//忘记密码
export function forgetPwd(data) {
  return request({
    url: '/account/forgetPwd',
    method: 'put',
    data
  })
}
//用户实名
export function realName(data) {
  return request({
    url: '/user/realName',
    method: 'put',
    data
  })
}
//学生志愿者申请
export function applyMemberInfo(data) {
  return request({
    url: '/user/applyMemberInfo',
    method: 'post',
    data
  })
}
//会员志愿者申请
export function applyStudentInfo(data) {
  return request({
    url: '/user/applyStudentInfo',
    method: 'post',
    data
  })
}
// 获取banner列表
export function getBanner() {
  return request({
    url: '/web/banner',
    method: 'get',
    params: {
      currPage: 1,
      pageLimit: 100
    }
  })
}
// 获取栏目列表
export function getColumn() {
  return request({
    url: '/web/column/selectList',
    method: 'get',
  })
}
// 获取首页列表
export function getHome(params) {
  return request({
    url: '/web/column/selectFirstPage',
    method: 'get',
    params
  })
}
// 获取关于我们列表
export function getAboutUs() {
  return request({
    url: '/web/aboutUs',
    method: 'get',
    params: {
      currPage: 1,
      pageLimit: 100
    }
  })
}
// 关于我们详情
export function getAboutDetail(params) {
  return request({
    url: '/web/aboutUs/' + params,
    method: 'get',
  })
}
// 新闻类型
export function getNewsType() {
  return request({
    url: '/web/newsMessage/type',
    method: 'get',
    params: {
      currPage: 1,
      pageLimit: 100
    }
  })
}
// 新闻列表
export function getNewsList(params) {
  return request({
    url: '/web/newsMessage',
    method: 'get',
    params
  })
}
// 热门资讯
export function getHotNews() {
  return request({
    url: '/web/newsMessage/selectHotNews',
    method: 'get',
  })
}
// 资讯详情
export function getNewsDetail(params) {
  return request({
    url: '/web/newsMessage/' + params,
    method: 'get',
  })
}
//志愿项目列表
export function volunteerItem(params) {
  return request({
    url: '/web/volunteerItem',
    method: 'get',
    params
  })
}
//推荐项目列表
export function volunteerRecommend(data) {
  return request({
    url: '/web/volunteerItem/recommend',
    method: 'post',
    data
  })
}
// 项目详情
export function volunteerItemDetail(params) {
  return request({
    url: '/web/volunteerItem/' + params,
    method: 'get',
  })
}
//用户详情
export function getUser() {
  return request({
    url: '/user',
    method: 'get',
  })
}
//修改用户详情
export function updateUser(data) {
  return request({
    url: '/user/updateUser',
    method: 'put',
    data
  })
}
//会员详情
export function memberInfo(params) {
  return request({
    url: '/user/memberInfo',
    method: 'get',
    params
  })
}
//学生详情
export function studentInfo(params) {
  return request({
    url: '/user/studentInfo',
    method: 'get',
    params
  })
}
//我的组织
export function queueInfo(data) {
  return request({
    url: '/front-api/queue/queue/baseInfo',
    method: 'post',
    data
  })
}
//志愿者参与活动数量和累计服务时长
export function volunteer(data) {
  return request({
    url: '/front-api/certificateApply/vip/volunteer',
    method: 'post',
    data
  })
}
//服务队组织类型
export function organizationList() {
  return request({
    url: '/front-api/queue/public/organization',
    method: 'get',
    params: {
      currPage: 1,
      pageLimit: 100
    }
  })
}
//服务队列表
export function queueList(data) {
  return request({
    url: '/front-api/queue/public/official/queueList',
    method: 'post',
    data
  })
}
//服务队详情
export function queueDetail(data) {
  return request({
    url: '/front-api/queue/public/queue/info',
    method: 'post',
    data
  })
}
//服务队留言列表
export function leaveMessageList(data) {
  return request({
    url: '/front-api/leaveMessage/public/list',
    method: 'post',
    data
  })
}
//发布服务队留言
export function saveLeaveMessage(data) {
  return request({
    url: '/front-api/leaveMessage/save',
    method: 'post',
    data
  })
}
//志愿者服务队/志愿活动-联系人
export function queueContacts(data) {
  return request({
    url: '/front-api/queue/public/queue/contacts',
    method: 'post',
    data
  })
}
//服务队/活动-关注/取消
export function attention(data) {
  return request({
    url: '/front-api/attention/queue',
    method: 'put',
    data
  })
}
//志愿者服务队-加入/取消加入
export function passOut(data) {
  return request({
    url: '/front-api/queue/queue/passOut',
    method: 'put',
    data
  })
}
//志愿活动列表
export function activityList(data) {
  return request({
    url: '/front-api/queue/public/activity/list',
    method: 'post',
    data
  })
}
//志愿活动分类
export function activityTypeList() {
  return request({
    url: '/front-api/queue/public/type/list',
    method: 'get',
    params: {
      currPage: 1,
      pageLimit: 100
    }
  })
}
//志愿活动评论列表
export function evaluateList(data) {
  return request({
    url: '/front-api/queue/public/evaluate/list',
    method: 'post',
    data
  })
}
//志愿活动发表评论
export function evaluate(data) {
  return request({
    url: '/front-api/queue/activity/evaluate',
    method: 'post',
    data
  })
}
//志愿活动删除
export function activityDel(data) {
  return request({
    url: '/front-api/queue/activity/delete',
    method: 'delete',
    data
  })
}
//志愿活动删除评论
export function evaluateDel(data) {
  return request({
    url: '/front-api/queue/evaluate/delete',
    method: 'delete',
    data
  })
}
//申请加入活动
export function applyActivity(data) {
  return request({
    url: '/front-api/queue/activity/apply',
    method: 'post',
    data
  })
}
//取消报名活动
export function cancelActivity(data) {
  return request({
    url: '/front-api/queue/activity/cancel',
    method: 'put',
    data
  })
}
//搜索成员列表
export function userList(data) {
  return request({
    url: '/front-api/queue/user/list',
    method: 'post',
    data
  })
}
//创建服务队
export function createQueue(data) {
  return request({
    url: '/front-api/queue/queue/create',
    method: 'post',
    data
  })
}
// 志愿服务队详情
export function queueDetailInfo(data) {
  return request({
    url: '/front-api/queue/info',
    method: 'post',
    data
  })
}
//编辑服务队
export function queueUpdate(data) {
  return request({
    url: '/front-api/queue/queue/update',
    method: 'put',
    data
  })
}
//地区列表
export function addressList(data) {
  return request({
    url: '/front-api/queue/public/address/change',
    method: 'post',
    data
  })
}
//查询审核通过拒绝消息
export function applyNotice() {
  return request({
    url: '/volunteerApply/applyNotice',
    method: 'get',
  })
}
//关闭消息
export function closeNotice(data) {
  return request({
    url: '/volunteerApply/closeNotice/'+data,
    method: 'put',
  })
}
//管理中心首页
export function adminInfo(data) {
  return request({
    url: '/front-api/queue/queue/adminInfo',
    method: 'post',
    data
  })
}
//管理中心-组员列表
export function queueUserList(data) {
  return request({
    url: '/front-api/queue/queue/userList',
    method: 'post',
    data
  })
}
//管理中心-组员审核-列表
export function queueOrganizationList(data) {
  return request({
    url: '/front-api/queue/organization/list',
    method: 'post',
    data
  })
}
//管理中心-组员管理-列表
export function auditList(data) {
  return request({
    url: '/front-api/queue/organization/auditList',
    method: 'post',
    data
  })
}
//管理中心-组员管理-列表审核
export function queueOrganizationStatus(data) {
  return request({
    url: '/front-api/queue/organization/status',
    method: 'put',
    data
  })
}
//管理中心-组员管理-删除成员
export function queueOrganizationDel(data) {
  return request({
    url: '/front-api/queue/organization/delete',
    method: 'delete',
    data
  })
}
//管理中心-活动管理-列表
export function activityAdminList(data) {
  return request({
    url: '/front-api/queue/activity/adminList',
    method: 'post',
    data
  })
}
//管理中心-活动管理-审核列表
export function activityAuditList(data) {
  return request({
    url: '/front-api/queue/activity/auditList',
    method: 'post',
    data
  })
}
//管理中心-活动管理-活动创建
export function createActivity(data) {
  return request({
    url: '/front-api/queue/activity/create',
    method: 'post',
    data
  })
}
//管理中心-活动管理-活动修改
export function updateActivity(data) {
  return request({
    url: '/front-api/queue/activity/update',
    method: 'put',
    data
  })
}
//管理中心-活动管理-活动详情
export function activityInfo(data) {
  return request({
    url: '/front-api/queue/public/activity/info',
    method: 'post',
    data
  })
}
//管理中心-活动管理-报名管理-基础信息
export function simpleActivityInfo(data) {
  return request({
    url: '/front-api/queue/activity/admin',
    method: 'post',
    data
  })
}
//管理中心-活动管理-报名管理-列表
export function recruitList(data) {
  return request({
    url: '/front-api/queue/activity/recruitList',
    method: 'post',
    data
  })
}
//管理中心-活动管理-报名管理-审核
export function recruitAudit(data) {
  return request({
    url: '/front-api/queue/activity/recruitAudit',
    method: 'put',
    data
  })
}
//管理中心-活动管理-报名管理-时长确认
export function activityConfirm(data) {
  return request({
    url: '/front-api/queue/activity/confirm',
    method: 'put',
    data
  })
}
//管理中心-活动管理-报名管理-图片保存
export function activityImgSave(data) {
  return request({
    url: '/front-api/queue/activity/prove',
    method: 'post',
    data
  })
}
//管理中心-活动图片
export function activityImgs(data) {
  return request({
    url: '/front-api/activity/activity/imgs',
    method: 'post',
    data
  })
}
//查询特定用户权限
export function privilegeInfo(data) {
  return request({
    url: '/front-api/user/privilege/info',
    method: 'post',
    data
  })
}
//管理中心-设置权限
export function setPrivilege(data) {
  return request({
    url: '/front-api/user/privilege/update',
    method: 'put',
    data
  })
}
//管理中心-更换负责人
export function changeAdmin(data) {
  return request({
    url: '/front-api/queue/queue/changeAdmin',
    method: 'put',
    data
  })
}
//管理中心-解散服务队
export function dissolve(data) {
  return request({
    url: '/front-api/queue/queue/dissolve',
    method: 'put',
    data
  })
}
//我的关注
export function attentionList(data) {
  return request({
    url: '/front-api/attention/list',
    method: 'post',
    data
  })
}
//已报名活动
export function applyList(data) {
  return request({
    url: '/front-api/activity/apply/list',
    method: 'post',
    data
  })
}
//活动记录
export function activityLogs(data) {
  return request({
    url: '/front-api/activity/activity/logs',
    method: 'post',
    data
  })
}
//修改密码
export function updatePwd(data) {
  return request({
    url: '/front-api/account/updatePwd',
    method: 'post',
    data
  })
}
//修改手机
export function updatePhone(data) {
  return request({
    url: '/front-api/account/update',
    method: 'post',
    data
  })
}
//证书申请-提交
export function certificateStu(data) {
  return request({
    url: '/front-api/certificateApply/apply/certificateStu',
    method: 'post',
    data
  })
}
//证书申请记录
export function applyLogs(data) {
  return request({
    url: '/front-api/certificateApply/apply/logs',
    method: 'post',
    data
  })
}
//证书用户信息
export function certificateInfo(data) {
  return request({
    url: '/front-api/certificateApply/certificate/info',
    method: 'post',
    data
  })
}
//证书记录详情
export function logsInfo(data) {
  return request({
    url: '/front-api/certificateApply/logs/info',
    method: 'post',
    data
  })
}
//证书申请修改
export function certificateUpdate(data) {
  return request({
    url: '/front-api/certificateApply/apply/certificateUpdate',
    method: 'put',
    data
  })
}
//证明详情
export function proveInfo(data) {
  return request({
    url: '/front-api/serverProve/prove/info',
    method: 'post',
    data
  })
}
//证明-申请
export function proveApply(data) {
  return request({
    url: '/front-api/serverProve/prove/apply',
    method: 'post',
    data
  })
}
//证明申请记录
export function proveLogs(data) {
  return request({
    url: '/front-api/serverProve/prove/applyLogs',
    method: 'post',
    data
  })
}
//证明申请记录详情
export function applyLogsInfo(data) {
  return request({
    url: '/front-api/serverProve/prove/applyLogsInfo',
    method: 'post',
    data
  })
}
//证明-收获地址修改
export function proveUpdate(data) {
  return request({
    url: '/front-api/serverProve/prove/update',
    method: 'put',
    data
  })
}