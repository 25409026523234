<!--
 * @Author: your name
 * @Date: 2021-07-23 13:33:18
 * @LastEditTime: 2021-08-12 09:42:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\App.vue
-->
<template>
  <div id="app" :style="{ height: $store.state.clientHeight + 'px' }">
    　<el-scrollbar
      style="height: 100%; ovflow-x: hidden"
      class="custom_scrollbar"
    >
      <router-view />
      　
    </el-scrollbar>
  </div>
</template>
<script>
import { getColumn } from "@/api/index";
export default {
  created() {
    getColumn().then((res) => {
      let column = res.data.map((item) => {
        return item.columnName;
      });
     this.$store.commit("SET_COLUMN", column);
    });
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
.custom_scrollbar {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
  .is-vertical {
    z-index: 1000;
  }
}
</style>
