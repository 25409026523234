<!--
 * @Author: your name
 * @Date: 2021-07-24 13:19:00
 * @LastEditTime: 2021-09-28 13:46:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\publicHeader.vue
-->
<template>
  <footer v-if="show">
    <div class="link container">
      <div class="title">友情链接：</div>
      <ul class="flex-h">
        <a href="http://www.cvsf.org.cn" target="_blank"></a>
        <a href="http://www.moe.gov.cn" target="_blank"></a>
        <a href="http://www.wenming.cn" target="_blank"></a>
        <a href="https://www.redcross.org.cn" target="_blank"></a>
        <a href="https://www.acftu.org" target="_blank"></a>
        <a href="https://www.cvf.org.cn" target="_blank"></a>
        <a href="http://www.nhc.gov.cn" target="_blank"></a>
        <a href="http://www.gqt.org.cn" target="_blank"></a>
        <a href="http://www.mca.gov.cn" target="_blank"></a>
        <a href="http://www.cnwomen.com.cn" target="_blank"></a>
        <a href="http://www.chinazyz.cn" target="_blank"></a>
      </ul>
    </div>
    <div class="footer">
      <div class="footerBox flex-v flex-vc flex-hc">
        <img src="../assets/img/qrcode.jpg" />
        <span class="text">扫码关注少艺委公众号</span>
      </div>
      <div class="copyright">
        Copyright© 2021 少儿艺术公益项目管理委员会 All Rights
        Reserved <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2020038766号-1</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      show:true
    }
  },
  watch: {
    $route(to, from) {
      if (to.matched[1].path == "/user") {
        this.show=false
      }else{
        this.show=true
      }
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  .link {
    height: 488px;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4f4f4f;
      padding-top: 36px;
    }
    ul {
      flex-wrap: wrap;
      a {
        display: block;
        width: 200px;
        height: 150px;
        margin-bottom: 38px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        &:nth-of-type(1) {
          background-image: url("../assets/img/link1.png");
        }
        &:nth-of-type(2) {
          background-image: url("../assets/img/link2.png");
        }
        &:nth-of-type(3) {
          background-image: url("../assets/img/link3.png");
        }
        &:nth-of-type(4) {
          background-image: url("../assets/img/link4.png");
        }
        &:nth-of-type(5) {
          background-image: url("../assets/img/link5.png");
        }
        &:nth-of-type(6) {
          background-image: url("../assets/img/link6.png");
        }
        &:nth-of-type(7) {
          background-image: url("../assets/img/link7.png");
        }
        &:nth-of-type(8) {
          background-image: url("../assets/img/link8.png");
        }
        &:nth-of-type(9) {
          background-image: url("../assets/img/link9.png");
        }
        &:nth-of-type(10) {
          background-image: url("../assets/img/link10.png");
        }
        &:nth-of-type(11) {
          background-image: url("../assets/img/link11.png");
        }
      }
    }
  }
  .footer {
    background: url("../assets/img/footer.png") no-repeat center center;
    .footerBox {
      height: 320px;
      img {
        width: 183px;
      }
      span {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin: 0 15px;
      }
      .text {
        margin: 20px 0;
      }
    }
    .copyright {
      height: 60px;
      background: #555555;
      line-height: 60px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      a{
        text-decoration:none;
        color: #ffffff;
      }
    }
  }
}
</style>